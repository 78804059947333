import { createBrowserRouter } from "react-router-dom";
import App from "../../App";
import BrowserRouterErrorPage from "../../components/errorPage/errorPage";
import Contact from "../../modules/contact/contact";
import Shop from "../../modules/shop/shop";
import Account from "../../modules/auth/account";
import Cart from "../../modules/cart/cart";
import PolicyPrivacy from "../../modules/infos/policyPrivacy";
import SalesRefunds from "../../modules/infos/salesRefunds";
import TermsConditions from "../../modules/infos/termsConditions";
import ForgotPassword from "../../modules/auth/screens/forgotPassword";
import CheckOutScreen from "../../modules/cart/checkOutScreen";
// 
// 
// routers names
import { AccountScreenRouterName, AdminAddProductScreenRouterName, AdminDashboardScreenRouterName, AdminManageImageScreenRouterName, AdminScreenRouterName, AdminViewOrdersDetailsScreenRouterName, AdminViewOrdersScreenRouterName, AdminViewProductScreenRouterName, AppHomeScreenRouterName, CartScreenRouterName, CheckOutScreenRouterName, ContactUsScreenRouterName, ForgotPasswordScreenRouterName, PolicyPrivacyScreenRouterName, ProfileScreenRouterName, SalesRefundsScreenRouterName, ShopScreenRouterName, TermsConditionsScreenRouterName, UserDetailsScreenRouterName, UserMoreScreenRouterName, UserNotificationScreenRouterName, UserPasswordSecurityScreenRouterName, UserPaymentDetailsScreenRouterName, UserSavedAddressScreenRouterName } from './routersNames';
import Profile from "../../modules/profile/profile";
import YourDetails from "../../modules/profile/screens/yourDetails";
import AccountMore from "../../modules/profile/screens/more";
import AccountNotification from "../../modules/profile/screens/notifications";
import AccountPasswordSecurity from "../../modules/profile/screens/passwordSecurity";
import AccountPaymentDetails from "../../modules/profile/screens/paymentDetails";
import AccountSavedAddress from "../../modules/profile/screens/savedAddress";
import Dev from "../../components/dev";
import AdminAddProduct from "../../modules/admin/addProduct";
import AdminViewProduct from "../../modules/admin/viewProducts";
import AdminViewOrder from "../../modules/admin/orders";
import AdminOrderDetails from "../../modules/admin/orderDetails";
import AdminIndex from "../../modules/admin/index";
import AdminDashboard from "../../modules/admin/dashboard";
import ScrollToTop from "../../components/scrollToTop";
import AdminImages from "../../modules/admin/images";


const AppRouter = createBrowserRouter([
  {
    path: `${AppHomeScreenRouterName}`,
    element: <>
      <ScrollToTop />
      <App />
    </>,
    errorElement: <BrowserRouterErrorPage />
  },
  {
    path: `${ContactUsScreenRouterName}`,
    element: <Contact />
  },
  {
    path: `${ShopScreenRouterName}`,
    element: <Shop />
  },
  {
    path: `${AccountScreenRouterName}`,
    element: <Account />
  },
  {
    path: `${CartScreenRouterName}`,
    element: <Cart />
  },
  {
    path: "/dev",
    element: <Dev />
  },
  {
    path: `${PolicyPrivacyScreenRouterName}`,
    element: (
      <PolicyPrivacy />
    )
  },
  {
    path: `${SalesRefundsScreenRouterName}`,
    element: (
      <SalesRefunds />
    )
  },
  {
    path: `${TermsConditionsScreenRouterName}`,
    element: (
      <TermsConditions />
    )
  },
  {
    path: `${ForgotPasswordScreenRouterName}`,
    element: (
      <ForgotPassword />
    )
  },
  {
    path: `${CheckOutScreenRouterName}`,
    element: (
      <CheckOutScreen />
    )
  },
  {
    path: `${ProfileScreenRouterName}`,
    element: (
      <Profile />
    )
  },
  {
    path: `${UserDetailsScreenRouterName}`,
    element: (
      <YourDetails />
    )
  },
  {
    path: `${UserMoreScreenRouterName}`,
    element: (
      <AccountMore />
    )
  },
  {
    path: `${UserNotificationScreenRouterName}`,
    element: (
      <AccountNotification />
    )
  },
  {
    path: `${UserPasswordSecurityScreenRouterName}`,
    element: (
      <AccountPasswordSecurity />
    )
  },
  {
    path: `${UserPaymentDetailsScreenRouterName}`,
    element: (
      <AccountPaymentDetails />
    )
  },
  {
    path: `${UserSavedAddressScreenRouterName}`,
    element: (
      <AccountSavedAddress />
    )
  },
  {
    path: `${AdminScreenRouterName}`,
    element: (
      <AdminIndex />
    )
  },
  {
    path: `${AdminDashboardScreenRouterName}`,
    element: (
      <AdminDashboard />
    )
  },
  {
    path: `${AdminAddProductScreenRouterName}`,
    element: (
      <AdminAddProduct />
    )
  },
  {
    path: `${AdminViewProductScreenRouterName}`,
    element: (
      <AdminViewProduct />
    )
  },
  {
    path: `${AdminViewOrdersScreenRouterName}`,
    element: (
      <AdminViewOrder />
    )
  },
  {
    path: `${AdminViewOrdersDetailsScreenRouterName}`,
    element: (
      <AdminOrderDetails />
    )
  },
  {
    path: `${AdminManageImageScreenRouterName}`,
    element: (
      <AdminImages />
    )
  },
]);

export default AppRouter;