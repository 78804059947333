// use this type when you wants to change color of the SVG
import React from 'react';

interface LockIcontProps {
  stroke: string;
  width: string;
  height: string;
}

const LockIcon: React.FC<LockIcontProps> = ({ stroke, height, width }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 90 116" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.2432 45.0882C25.2432 42.9004 25.2432 24.134 25.2432 24.134L36.5328 12.1602H53.4672L64.7568 24.134C64.7568 24.134 64.7568 42.9004 64.7568 45.0882" stroke={stroke} stroke-width="15.68" stroke-linecap="square" />
      <path d="M12.072 104.075V51.3906H77.928V104.075H12.072Z" stroke={stroke} stroke-width="15.68" stroke-linecap="square" />
    </svg>

  );
}

// 
export default LockIcon;
