// use this type when you wants to change color of the SVG

import React from 'react';

interface PackageIconProps {
  stroke: string;
  width: string;
  height: string;
}

const PackageIcon: React.FC<PackageIconProps> = ({ stroke, width, height }) => {

  return <svg width={width} height={height} viewBox="0 0 52 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.88 38.5401V15.9516L23.04 24.9116V48.6201L2.88 38.5401ZM28.8 48.6201V24.9116L48.96 15.9516V38.5401L28.8 48.6201ZM25.92 3.15164L44.7488 11.52L25.92 19.8884L7.09118 11.52L25.92 3.15164Z" stroke={stroke} stroke-width="5.76" />
  </svg>
}

//
export default PackageIcon;
