import { useNavigate } from "react-router-dom";
import { HOMEPAGE_IMAGE4_PROD500ML, HOMEPAGE_IMAGE5_PROD1L, HOMEPAGE_IMAGE5_PROD5L, LS_IMAGES } from "../../../utils/constants/constants";
import { ContactUsScreenRouterName } from "../../../utils/routers/routersNames";
import EyeIcon from "../../../utils/svg/eyeIcon";
import ProductImage from "./productImage";
import ArrowTopRight from "../../../utils/svg/arrowTopRight";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../utils/store/redux/hooks";
import { RootState } from "../../../utils/store/redux/store";
import { localStorage_getItem } from "../../../utils/store/localStorage/localStorage";



const Products: React.FC = () => {
  let images = useAppSelector((state: RootState) => state.contentImageSlice);
  const [image500ml, setImage500ml] = useState<string>("");
  const [image1l, setImage1l] = useState<string>("null");
  const [image5l, setImage5l] = useState<string>("null");
  const navigate = useNavigate();


  // 
  useEffect(() => {
    if (images && Object.keys(images).length === 0) {
      images = localStorage_getItem(LS_IMAGES);
      setImage1l(images['homepage1limage']);
      setImage500ml(images['homepage500mlimage']);
      setImage5l(images['homepage5limage']);
    }
  }, []);

  function handleContactUsBtn() { navigate(ContactUsScreenRouterName); }

  return <div className=' tablet768:mt-[6rem] ' >
    <div className=' uppercase flex flex-row
      text-[3.125rem] mobile:text-[3.75rem] mobilels:text-[4.75rem] 
      tablet768:text-[6.125rem] tablet:text-[8.125rem] desktopsm:text-[9.625rem] ' >
      <span className='w-10/12 ' >explore <br /> our range <br /> of alkaline <br /> water</span>
      <span className='pt-[0.4rem] ' ><EyeIcon width='52' height='52' stroke='#232526' /></span>
    </div>

    <div className=' flex flex-row items-center justify-between tablet:hidden ' >
      {/* image */}
      <div className="" >
        <ProductImage image={HOMEPAGE_IMAGE4_PROD500ML} desc={'the normal one'} product={'500ml'} />
        <div className=" mt-[1.5rem]" >
          <ProductImage image={HOMEPAGE_IMAGE5_PROD1L} desc={'the big one'} product={'1l'} />
        </div>
      </div>
      <ProductImage image={HOMEPAGE_IMAGE5_PROD5L} desc={'the bigger one'} product={'5l'} />
    </div>

    <div className=' hidden tablet:flex tablet:justify-between tablet:mt-[2rem] ' >
      {/* image */}
      <div className=" mt-[8rem] " >
        <ProductImage image={HOMEPAGE_IMAGE4_PROD500ML} desc={'the normal one'} product={'500ml'} />
      </div>
      <div className="" >
        <ProductImage image={HOMEPAGE_IMAGE5_PROD1L} desc={'the big one'} product={'1l'} />
      </div>
      <div className=" mt-[-10rem] " >
        <ProductImage image={HOMEPAGE_IMAGE5_PROD5L} desc={'the bigger one'} product={'5l'} />
      </div>
    </div>

    {/*  */}
    <div className=' tablet768:mt-[-7rem] tablet:mt-[-12rem] pt-[3.5rem] pb-[3.5rem] uppercase relative 
       tablet768:flex tablet768:justify-end tablet768:items-end ' >
      <div className=' flex flex-col text-[4rem] desktopsm:text-[5.25rem] ' >
        <span className=' tablet768:text-right ' >need <button className=' tablet768:relative absolute right-0 mt-[1rem] active:bg-light-grey7 hover:bg-light-grey2 hover:rounded-[0.2rem] hover:px-[0.4rem] hover:py-[0.1rem] '
          onClick={() => handleContactUsBtn()} >
          <ArrowTopRight width='45' height='54' stroke='#232526' /></button> <br />
        </span>

        <span >something <br /> bigger?</span>

        <span className=' font-inter text-locked-grey text-[1.25rem] ' >contact us!</span>
      </div>
    </div>
  </div>
}


export default Products;