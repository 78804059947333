// use this type when you wants to change color of the SVG

import React from 'react';

interface ArrowDownIconProps {
  stroke: string;
  width: string;
  height: string;
}

const ArrowDownIcon: React.FC<ArrowDownIconProps> = ({ stroke, width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M30.9023 15.5581L28.1305 12.7863L17.7564 23.1603V0.452206H13.8364L13.8364 23.1603L3.46234 12.7863L0.690487 15.5581L15.7964 30.6641L30.9023 15.5581Z" fill={stroke} />
    </svg>
  );
}

// 31, 232526
export default ArrowDownIcon;
