// use this type when you wants to change color of the SVG
import React from 'react';


interface HomeIconProps {
  stroke: string;
  width: string;
  height: string;
}

const HomeIcon: React.FC<HomeIconProps> = ({ stroke, width, height }) => {

  return <svg width={width} height={height} viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 9L4 11V19H18V11L16 9V17H6V9Z" fill={stroke} />
    <path d="M2 12L11 4L20 12" stroke={stroke} stroke-width="2" />
    <path d="M9 17.5V11.5H13V17.5" stroke={stroke} stroke-width="2" />
    <path d="M6 8V4.5" stroke={stroke} stroke-width="2" />
  </svg>

}

export default HomeIcon;
