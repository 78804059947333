// use this type when you wants to change color of the SVG

import React from 'react';

interface NotificationIconProps {
  stroke: string;
  width: string;
  height: string;
}

const NotificationIcon: React.FC<NotificationIconProps> = ({ stroke, width, height }) => {

  return <svg width={width} height={height} viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8293 18.5001H8.17072C8.58258 19.6652 9.6938 20.5 11 20.5C12.3062 20.5 13.4174 19.6652 13.8293 18.5001ZM11.0181 14.5001H10.9819C10.9879 14.5 10.994 14.5 11 14.5C11.006 14.5 11.0121 14.5 11.0181 14.5001ZM8.00002 17.4877C8.00001 17.4918 8 17.4959 8 17.5C8 17.5041 8.00001 17.5082 8.00002 17.5123V17.4877Z" fill={stroke} />
    <path d="M8.19258 16.5L4.38046 14.9752L5.89443 11.9472L6 11.7361V11.5V7.03518L8.5547 5.33205L9 5.03518V4.5V3.91421L9.41421 3.5H12.5858L13 3.91421V4.5V5.03518L13.4453 5.33205L16 7.03518V11.5V11.7361L16.1056 11.9472L17.6195 14.9752L13.8074 16.5H8.19258Z" stroke={stroke} stroke-width="2" />
  </svg>
}

//
export default NotificationIcon;
