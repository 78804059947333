// use this type when you wants to change color of the SVG

import React from 'react';

interface CheckMarkIconProps {
  stroke: string;
  width: string;
  height: string;
}

const CheckMarkIcon: React.FC<CheckMarkIconProps> = ({ stroke, width, height }) => {

  return <svg width={width} height={height} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.2422 6.5L8.24219 15.5L3.75732 11" stroke={stroke} stroke-width="2" />
  </svg>

}

export default CheckMarkIcon;
