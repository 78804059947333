// use this type when you wants to change color of the SVG
import React from 'react';


interface TrashIconProps {
  stroke: string;
  width: string;
  height: string;
}

const TrashIcon: React.FC<TrashIconProps> = ({ stroke, width, height }) => {

  return <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 6C8 5.63458 8 4.5 8 4.5L9 3.5H13L14 4.5C14 4.5 14 5.63458 14 6" stroke={stroke} stroke-width="2" stroke-linecap="square" />
    <path d="M4.5 7H6M17.5 7H16M6 7L7 19H15L16 7M6 7H16" stroke={stroke} stroke-width="2" stroke-linecap="square" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6262 8.5H13.9996L13.4856 17.5H12.1122L12.6262 8.5ZM10.3143 8.5H11.6851V17.5H10.3143V8.5ZM8 8.5H9.37341L9.88744 17.5H8.51403L8 8.5Z" fill={stroke} />
  </svg>

}

export default TrashIcon;
