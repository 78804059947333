// use this type when you wants to change color of the SVG
import React from 'react';

interface PlusIconProps {
  stroke: string;
  width: string;
  height: string;
}

const PlusIcon: React.FC<PlusIconProps> = ({ stroke, height, width }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.85711 10.8572L9.85711 16.771H8.14282L8.14282 10.8572L2.22904 10.8572L2.22904 9.14289L8.14282 9.14289L8.14282 3.229H9.85711L9.85711 9.14289L15.771 9.14289V10.8572L9.85711 10.8572Z" fill={stroke} />
    </svg>
  );
}
export default PlusIcon;
