
import React from 'react';

interface XCancelIconProps {
  stroke: string;
  width: string;
  height: string;
}

const XCancelIcon: React.FC<XCancelIconProps> = ({ stroke, width, height }) => {

  return <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_730_15706)">
      <path d="M3.07715 36.3071L36.9233 2.46094" stroke={stroke} stroke-width="5.53846" />
      <path d="M36.9229 36.3071L3.0767 2.46094" stroke={stroke} stroke-width="5.53846" />
    </g>
    <defs>
      <clipPath id="clip0_730_15706">
        <rect width="40" height="38.7692" fill="white" />
      </clipPath>
    </defs>
  </svg>
}

// 40 40 171718
export default XCancelIcon;
