
import React from 'react';

interface EditIconProps {
  stroke: string;
  width: string;
  height: string;
}

const EditIcon: React.FC<EditIconProps> = ({ stroke, width, height }) => {

  return <svg width={width} height={height} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7183 4H4V18H18V7.31785L16 9.31785V16H6V6H11.7183L13.7183 4Z" fill={stroke} />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3346 2.8207L18.6953 1.1814L10.5991 9.27759L9.32257 12.1934L12.2384 10.9169L20.3346 2.8207Z" fill={stroke} />
  </svg>

}

export default EditIcon;
