// use this type when you wants to change color of the SVG
import React from 'react';

interface EyeIconProps {
  stroke: string;
  width: string;
  height: string;
}

const EyeIcon: React.FC<EyeIconProps> = ({ stroke, height, width }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 11.2734C19 13.3188 17.8818 14.7857 16.2674 15.7878C14.6271 16.8058 12.5648 17.2734 11 17.2734C9.43523 17.2734 7.37286 16.8058 5.73262 15.7878C4.11815 14.7857 3 13.3188 3 11.2734C3 9.22811 4.11815 7.7612 5.73262 6.75912C7.37286 5.74103 9.43523 5.27344 11 5.27344C12.5648 5.27344 14.6271 5.74103 16.2674 6.75912C17.8818 7.7612 19 9.22811 19 11.2734Z" stroke={stroke} stroke-width="2" />
      <path d="M15.5 11.2734C15.5 12.8977 14.8106 13.9936 13.9175 14.7064C12.9908 15.4459 11.8379 15.7734 11 15.7734C10.1621 15.7734 9.00925 15.4459 8.08252 14.7064C7.18943 13.9936 6.5 12.8977 6.5 11.2734C6.5 9.64915 7.18943 8.55323 8.08252 7.84051C9.00925 7.10096 10.1621 6.77344 11 6.77344C11.8379 6.77344 12.9908 7.10096 13.9175 7.84051C14.8106 8.55323 15.5 9.64915 15.5 11.2734Z" stroke={stroke} stroke-width="2" />
      <path d="M12 11.2734C12 11.6551 11.8499 11.876 11.664 12.0244C11.4444 12.1996 11.1666 12.2734 11 12.2734C10.8334 12.2734 10.5556 12.1996 10.336 12.0244C10.1501 11.876 10 11.6551 10 11.2734C10 10.8918 10.1501 10.6709 10.336 10.5225C10.5556 10.3473 10.8334 10.2734 11 10.2734C11.1666 10.2734 11.4444 10.3473 11.664 10.5225C11.8499 10.6709 12 10.8918 12 11.2734Z" stroke={stroke} stroke-width="2" />
    </svg>

  );
}
// 22, 23, 232526
export default EyeIcon;
