import React, { useEffect, useState } from "react";
import { HOMEPAGE_IMAGE2, LS_IMAGES } from "../../../utils/constants/constants";
import { useAppSelector } from "../../../utils/store/redux/hooks";
import { RootState } from "../../../utils/store/redux/store";
import { localStorage_getItem } from "../../../utils/store/localStorage/localStorage";

const TEXT_DESCRIPTION: string = "Not all water is made the same, and they certainly don’t taste the same. Meticulously crafted, Solid Water is a revitalising elixir with a refreshing taste that’s gentle and smooth."


const Image2Component: React.FC = () => {

  let images = useAppSelector((state: RootState) => state.contentImageSlice);
  const [image, setImage] = useState<string | null>(null);


  // 
  useEffect(() => {
    if (images && Object.keys(images).length === 0) {
      images = localStorage_getItem(LS_IMAGES);
      setImage(images['homepagesecondimage']);
    }
  }, []);

  return <div className=' flex flex-col tablet768:mt-[2rem]
     tablet768:flex tablet768:flex-row tablet768:pb tablet768:pl-[2rem] tablet768:pt-[2rem] tablet:pl-[4rem] desktopsm:pl-[6rem] desktopmd:pl-[8rem] desktopmd:items-center   ' >
    {/*  */}

    <div className=' tablet768:w-6/12 tablet768:mt-[7rem] tablet:mt-[5rem] desktopsm:mt-[2rem] pt-[2rem] desktopmd:mt-0 ' >
      <img src={HOMEPAGE_IMAGE2} />
    </div>

    {/* {
      image && image.length > 0 ?
        (<div className=' tablet768:w-6/12 tablet768:mt-[7rem] tablet:mt-[5rem] desktopsm:mt-[2rem] pt-[2rem] desktopmd:mt-0 ' >
          <img src={HOMEPAGE_IMAGE2} />
        </div>)
        : (
          <div className="card tablet768:w-6/12 tablet768:mt-[7rem] tablet:mt-[5rem] desktopsm:mt-[2rem] pt-[2rem] desktopmd:mt-0 bg-slate-800">
            <div className="image-placeholder h-full text-center w-ful placeholder bg-slate-100"></div>
          </div>
        )
    } */}

    <div className=' desktopmd:pb-[3rem] pt-[1rem] font-inter text-[1.5rem] mobilels:text-[1.6875rem] desktopsm:text-[2.25rem]
      tablet768:w-5/12 tablet768:pt-0 tablet768:ml-[2rem]  ' >
      {TEXT_DESCRIPTION}
    </div>
  </div>
}


export default Image2Component;