// app route names
// 
// 
export const CheckOutScreenRouterName: string = "/shop/cart/checkout";
export const AppHomeScreenRouterName: string = "/";
export const ContactUsScreenRouterName: string = "/contactus";
export const ShopScreenRouterName: string = "/shop";
export const AccountScreenRouterName: string = "/account";
export const CartScreenRouterName: string = "/cart";
export const PolicyPrivacyScreenRouterName: string = "/policyprivacy";
export const SalesRefundsScreenRouterName: string = "/salesrefunds";
export const TermsConditionsScreenRouterName: string = "/termsconditions";
export const LoginScreenRouterName: string = "/auth/login";
export const RegisterScreenRouterName: string = "/auth/register";
export const ForgotPasswordScreenRouterName: string = "/auth/forgotpassword";
export const ProfileScreenRouterName: string = "/profile";
export const UserDetailsScreenRouterName: string = "/profile/userdetails";
export const UserPasswordSecurityScreenRouterName: string = "/profile/passwordsecurity";
export const UserNotificationScreenRouterName: string = "/profile/notification";
export const UserPaymentDetailsScreenRouterName: string = "/profile/paymentdetails";
export const UserSavedAddressScreenRouterName: string = "/profile/saveaddress";
export const UserMoreScreenRouterName: string = "/profile/more";
export const AdminScreenRouterName: string = "/admins";
export const AdminDashboardScreenRouterName: string = "/admins/dashboard";
export const AdminAddProductScreenRouterName: string = "/admins/addproduct";
export const AdminViewProductScreenRouterName: string = "/admins/products";
export const AdminViewOrdersScreenRouterName: string = "/admins/orders";
export const AdminViewOrdersDetailsScreenRouterName: string = "/admins/ordersdetails";
export const AdminManageImageScreenRouterName: string = "/admins/manageimages";

