import { useNavigate } from "react-router-dom";
import { AdminAddProductScreenRouterName, AdminManageImageScreenRouterName, AdminScreenRouterName, AdminViewOrdersScreenRouterName, AdminViewProductScreenRouterName } from "../../utils/routers/routersNames";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../utils/store/redux/hooks";
import { RootState } from "../../utils/store/redux/store";
import AdminHeader from "./header";
import { localStorage_getItem } from "../../utils/store/localStorage/localStorage";
import { LS_ADMIN_PROFILE } from "../../utils/constants/constants";
import { setUserAdminProfile } from "../../utils/store/redux/slices/userAdminProfile";


const AdminDashboard: React.FC = () => {
  const adminProfileInfo = useAppSelector((state: RootState) => state.userAdminProfile);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function handleAddProductBtn() {
    navigate(AdminAddProductScreenRouterName);
  }

  function handleViewProductBtn() {
    navigate(AdminViewProductScreenRouterName);
  }

  function handleOrdersBtn() {
    navigate(AdminViewOrdersScreenRouterName);
  }

  function handleImagesBtn() {
    navigate(AdminManageImageScreenRouterName);
  }

  useEffect(() => {
    // check ls for data
    const profileData = localStorage_getItem(LS_ADMIN_PROFILE);
    if (profileData) {
      dispatch(setUserAdminProfile(profileData));
      // if (Object.keys(adminProfileInfo).length === 0) { }
    }
    else {
      navigate(AdminScreenRouterName);
    }
  }, []);

  return <div className=" py-[2rem] px-[1rem] h-screen font-inter bg-white" >

    <AdminHeader />

    <div className=" mx-auto max-w-[40rem]" >
      {/*  */}
      <div className=" mb-[1rem] " >
        <button className=" text-white w-full bg-slate-500 px-[1rem] py-[0.5rem]"
          onClick={() => handleAddProductBtn()} >
          Add Product
        </button>
      </div>

      <div className=" mb-[1rem] " >
        <button className=" text-white w-full bg-slate-500 px-[1rem] py-[0.5rem]"
          onClick={() => handleViewProductBtn()} >
          View Product
        </button>
      </div>

      <div className=" mb-[1rem] " >
        <button className=" text-white w-full bg-slate-500 px-[1rem] py-[0.5rem]"
          onClick={() => handleOrdersBtn()} >
          View Orders
        </button>
      </div>

      {/* for image */}
      <div className=" mb-[1rem] " >
        <button className=" text-white w-full bg-slate-500 px-[1rem] py-[0.5rem]"
          onClick={() => handleImagesBtn()} >
          Manage Images
        </button>
      </div>
    </div>
  </div>
}

export default AdminDashboard;